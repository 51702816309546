import React from 'react'
import { ENGLISH_TITLE } from '../../components/data/English'
import { SEO } from '../../components/data/SEO'
import { sideNavDataCompany } from '../../components/data/sideNavData'
import { LayoutDefault } from '../../components/organisms/Layout/LayoutDefault'
import { Strength } from '../../components/organisms/Strength/Strength'

const Page = () => {
  return (
    <LayoutDefault
      heading="CRGグループの強み"
      headingEnglish={ENGLISH_TITLE.OurStrengths}
      BreadcrumbData={[
        { title: '企業情報', url: '/company' },
        { title: 'CRGグループの強み', url: '' },
      ]}
      sideNavHeading="企業情報"
      sideNavList={sideNavDataCompany}
    >
      <Strength />
    </LayoutDefault>
  )
}

export default Page

export const Head = () => (
  <SEO title="CRGグループの強み" url="/company/strength" />
)
